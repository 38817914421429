import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { AppSettings, IBookmaker, IdName, IPromoInfo } from '@bs/models';
import { AppSettingsService, BookmakersService } from '@bs/services';

const defaultOptions = {termsLink: true};

@Component({
  selector: 'promo-infos',
  templateUrl: './promo-infos.component.html',
  styles: [`

    .hasThumbs {
      margin-bottom: -50px;
    }

    .inner-error {
      position: absolute;
      bottom: -25px;
      width: 100%;
    }

    field-error.inner-error::ng-deep .p-inline-message {
      padding: 2px;
      width: 100%;
      justify-content: flex-start;
    }

    p-card.en {
      order: -1;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromoInfosComponent),
      multi: true
    }
  ]
})
export class PromoInfosComponent implements ControlValueAccessor, OnInit {

  model: Array<IPromoInfo> = [];
  settings: AppSettings;
  form: FormGroup;
  isDisabled: boolean;
  control: NgControl;

  bookmakers: Array<IBookmaker>;
  currentBookmaker: IBookmaker;
  activeIndex: number = 0;

  @Input()
  options: any = {};

  @Output() blur = new EventEmitter<any>(null);

  constructor(private settingService: AppSettingsService, private fb: FormBuilder, @Optional() private container: ControlContainer, private bookmakersService: BookmakersService) {

    this.form = fb.group({});
    this.bookmakers = bookmakersService.bookmakers$.getValue();

  }

  ngOnInit() {
    // if valid propagate values, otherwise null
    if (this.options.isEdit) {
      this.form.statusChanges.subscribe({
        next: status => this.propagateChange(status === 'VALID' ? this.form.value?.infos[this.activeIndex] : null)
      })
    } else {
      this.form.statusChanges.subscribe({
        next: status => this.propagateChange(status === 'VALID' ? this.form.value?.infos : null)
      })
    }

    if (this.options?.bookmakerId) {
      // bookmaker comes from input
      this.currentBookmaker = this.bookmakers.find(b => b.id === this.options.bookmakerId);
      this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
    } else {
      /**
       * get parent BookmakerId
       */
      const bookmakerIdCtrl = this.container.control.get('bookmakerId');

      bookmakerIdCtrl?.valueChanges.subscribe({
        next: bmId => {

          this.currentBookmaker = this.bookmakers.find(b => b.id === bmId);

          if (this.form.contains('infos')) {
            this.form.removeControl('infos');
          }
          this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
        }
      })

      this.options = {...defaultOptions, ...this.options};

      this.settingService.appSettings$.subscribe({
        next: ({settings}) => {
          this.settings = settings;
          this.currentBookmaker = settings.bookmaker;

          if (this.form.contains('infos')) {
            this.form.removeControl('infos');
          }

          this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
        }
      });
    }
  }

  private propagateChange(_model: Array<IPromoInfo>) {
  }

  onBlur(event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
  }

  onTouched() {
  }

  get infos() {
    return this.form.get('infos') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(obj: Array<IPromoInfo>) {
    if (obj) {
      obj.forEach(pi => {
        const infoCtrl = this.infos.controls.find(c => c.value.languageId === pi.languageId);
        infoCtrl.patchValue(pi);
      })
    }
  }

  private buildForm(languages: Array<IdName<string>>): FormArray {
    const arr = [];

    languages.forEach(l => {

      const info = this.fb.group({
        languageId: l.id,
        name: ['', Validators.required],
        description: ['', Validators.required],
        terms: ['', Validators.required],
      });

      arr.push(info);
    })

    return this.fb.array(arr);
  }

  copyAll() {
    // const toCopyFrom = this.infos.controls.find(c => c.value.languageId === copyAll).value;
    const first = this.infos.controls[0].value;

    this.infos.controls.forEach(ctrl => {
      ctrl.patchValue({
        description: first.description,
        name: first.name,
        terms: first.terms
      });
    })
  }

  onTabChange($event: any) {
    if (this.options.isEdit) {
      this.activeIndex = $event.index
      this.propagateChange(this.form.value?.infos[this.activeIndex])
    }
  }
}
