import { KeyValue } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFactoryService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';

/**
 * DateRange model declaration
 */
interface DateRange {
  /**
   * from date
   */
  from: Date;
  /**
   * to date
   */
  to: Date;
}

/**
 * The component contains the from, to date input fields
 */
@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ]
})
export class DateRangeComponent implements ControlValueAccessor, OnInit {
  /**
   * whether to display a timepicker for the calendar
   */
  @Input()
  showTime = true;
  /**
   * mode of the date range
   *
   * @description decides whether we use a buttons or dropdown option for the date range
   */
  @Input()
  mode = 'select';
  /**
   * the maximum allowed date of the date range
   */
  @Input()
  maxDate: Date;
  /**
   * the minimum allowed date of the date range
   */
  @Input()
  minDate: Date;
  /**
   * options passed by parent
   */
  @Input()
  options: any;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: DateRange;
  /**
   * responsible for disabling the field
   */
  disabled = true;
  /**
   * keeps track of the presets dropdown value, when user interaction on the control, it keeps the view synced with the model
   */
  selectedPreset: KeyValue<string, DateRange>;
  /**
   * declaring array of any type
   */
  presets: any[];

  /**
   * storing from value into string
   * @private
   */

  private from: string;
  /**
   * storing to value into string
   * @private
   */
  private to: string;

  /**
   * The constructor where we set default value for the presets variable
   * @param translate
   * @param dateFactory
   */
  constructor(private translate: TranslateService, private dateFactory: DateFactoryService) {
  }

  ngOnInit() {
    const basePreset = [
      {key: this.translate.instant('today'), value: {from: this.setFrom(0, 0), to: this.setTo(0, 0)}},
      {key: this.translate.instant('yesterday'), value: {from: this.setFrom(-1, 0), to: this.setTo(-1, 0)}},
      {key: this.translate.instant('lastWeek'), value: {from: this.setFrom(-7, 0), to: this.setTo(0, 0)}},
      {key: this.translate.instant('lastMonth'), value: {from: this.setFrom(0, -1), to: this.setTo(0, 0)}},
      {key: this.translate.instant('currentMonth'), value: {from: this.setFrom(null, 0), to: this.setTo(0, 0)}},
      {key: this.translate.instant('custom'), value: {from: this.setFrom(0, 0), to: this.setTo(0, 0)}},
    ];

    const futurePreset = [
      {key: 'nextWeek', value: {from: this.setFrom(0, 0), to: this.setTo(7, 0)}},
      {key: 'nextMonth', value: {from: this.setFrom(0, 0), to: this.setTo(0, 1)}},
      {key: 'custom', value: {from: null, to: null}},
    ]

    const availablePresets = {
      'base': basePreset,
      'future': futurePreset
    }
    this.presets = this.options?.preset ? availablePresets[this.options.preset] : availablePresets.base;

    this.preset(this.presets[0]); // today

    if (this.options?.custom) {
      this.preset(this.presets[5]); // custom

      this.minDate = new Date(this.options.custom.startSession);

      if (this.options.custom?.endSession) {
        this.maxDate = new Date(this.options.custom.endSession);
      }
    }
  }

  /**
   * on dropdown or button preset value change we update the model value, and propagate the change
   * @param preset
   */
  preset(event: { key: string, value: DateRange }) {
    this.selectedPreset = event;
    this.model = event.value;
    this.disabled = this.mode === 'buttons' ? false : event.key.toLowerCase() !== 'custom';
    this.from = this.dateFactory.toTz(this.model.from, false);
    this.to = this.dateFactory.toTz(this.model.to, true);
    this.propagateChange({from: this.from, to: this.to});
  }

  /**
   * sets the values for the model variables, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: DateRange) {
    if (obj && obj.from && obj.to) {

      setTimeout(() => {
        this.model = {
          from: new Date(obj.from),
          to: new Date(obj.to)
        };
        this.selectedPreset = this.presets.find(p => p.key === 'custom');
        this.disabled = false;
      }, 100)
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
    setTimeout(
      () => {
        this.propagateChange({from: this.from, to: this.to});
      }
    );
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * on field when ngModelChanges it's value, we propagate the value change
   * @param _model
   */
  update(_model: { from: Date, to: Date }) {
    this.model = _model;
    this.from = this.dateFactory.toTz(_model.from, false);
    this.to = this.dateFactory.toTz(_model.to, true);
    this.propagateChange({from: this.from, to: this.to});
  }

  /**
   * function sets a date time to 00:00
   * it sets 'from' at 00:00
   *
   * @param day
   * @param month
   * @private
   */
  private setFrom(day?, month?): Date {
    const date = this.setDate(day, month);
    return new Date(date.setHours(0, 0, 0, 0));
  }

  /**
   * function sets a date time to 23:59
   *
   * @description it sets 'to' at 23:59
   *
   * @param day
   * @param month
   * @private
   */
  private setTo(day?, month?): Date {
    const date = this.setDate(day, month);
    return new Date(date.setHours(23, 59, 59, 999));
  }

  /**
   * function return us new date calculating date from now and passed day and month
   * @param day
   * @param month
   * @private
   */
  private setDate(day?, month?): Date {
    const now = new Date();
    return new Date(day === null ? now.setDate(1) : now.setFullYear(now.getFullYear(), now.getMonth() + month, now.getDate() + day));
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: { from: string, to: string }) {
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

}
